/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Format {
    STANDARD = 'standard',
    STARTUP = 'startup',
    ETERNAL = 'eternal',
    OTHER = 'other',
}
